import React from "react";
import "./animation.css";
import variant1 from "../../assets/variant1.webp";
import variant2 from "../../assets/variant2.webp";
import variant3 from "../../assets/variant3.webp";
import variant4 from "../../assets/variant4.webp";
import variant5 from "../../assets/variant5.webp";
import variant6 from "../../assets/variant6.webp";

const AnimationAboutPage = () => {
  return (
    <>
      <div className='variant1'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant2'>
        <img src={variant2} alt='' />
      </div>
      <div className='variant3'>
        <img src={variant3} alt='' />
      </div>
      <div className='variant4'>
        <img src={variant4} alt='' />
      </div>
      <div className='variant5'>
        <img src={variant5} alt='' />
      </div>
      <div className='variant6'>
        <img src={variant6} alt='' />
      </div>
      <div className='variant7'>
        <img src={variant2} alt='' />
      </div>
      <div className='variant8'>
        <img src={variant3} alt='' />
      </div>
      <div className='variant9'>
        <img src={variant4} alt='' />
      </div>
      <div className='variant10'>
        <img src={variant6} alt='' />
      </div>
      <div className='variant11'>
        <img src={variant5} alt='' />
      </div>
      <div className='variant12'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant13'>
        <img src={variant2} alt='' />
      </div>
      <div className='variant14'>
        <img src={variant3} alt='' />
      </div>
      <div className='variant15'>
        <img src={variant4} alt='' />
      </div>
      <div className='variant16'>
        <img src={variant6} alt='' />
      </div>
      <div className='variant17'>
        <img src={variant5} alt='' />
      </div>
      <div className='variant18'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant19'>
        <img src={variant2} alt='' />
      </div>
    </>
  );
};

export default AnimationAboutPage;
