import React from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./index.css";
import Routers from "./router/Routers";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop>
        <Routers />
      </ScrollToTop>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
