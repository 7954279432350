import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import visops2 from "../../../assets/visops-ss1.webp";
import visopsimg1 from "../../../assets/visops-img1.webp";
import visopsimg3 from "../../../assets/visops-img3.webp";
import './VisualOps.css'
import visops from "../../../assets/visopsmain.webp";

export const VisualOps = () => {
  return (
    <div className="visopsContainer">
      <div className="visops-main-img">
        <img src={visops} alt="technology" />
      </div>
      <div
        style={{ backgroundImage: visopsimg1 }}
        className="visops-container section__padding"
      >
        <div className="visops-container_left" id="technology">
          <div className="visops-content">
            <h2>Visually Architect</h2>
            <p>
              ZeroT Energy - Visual Ops platform allows you to Drag and Drop
              Resources in such a way that it simplifies the process of adding
              and organizing resources in a cloud infrastructure environment. By
              allowing users to drag and drop resources onto a canvas , this
              feature makes it easier to create and manage cloud infrastructure.
              It provides dragging a connection from an output port of one node
              to an input port of another node for connecting two resources.
            </p>
          </div>
          <div className="visops-img">
            <img src={visops2} alt="technology" />
          </div>
        </div>
        <div className="visops-container_right" id="internet-of-things">
          <div className="visops-content">
            <h2>Deployment Environment</h2>
            <p>
              <ul>
                Deployments in Visual Ops makes it easy to configure various
                aspects of the deployment process, such as :
                {/* the deployment
                script, environment variables, port number, and health check
                configuration. */}
                <li>
                  Customizing the deployment script to be executed
                  during the deployment process.
                </li>
                <li>
                  Managing environment variables passed to the
                  application in runtime.
                </li>
                <li>
                  Specify the port number on which the
                  application will listen.
                </li>
                <li>
                  Health check configuration to monitor the status of your
                  application and automatically terminate unhealthy instances
                </li>
              </ul>
            </p>
          </div>
          <div className="visops-img">
            <img src={visopsimg3} alt="tools-for-work" />
          </div>
        </div>
      </div>
    </div>
  );
};
