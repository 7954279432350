import React, { useState, useEffect } from "react";
import "./esg.css";
import AOS from "aos";
import "aos/dist/aos.css";
import esg1 from "../../assets/pic8.webp";
import esg2 from "../../assets/pic9.webp";
import subtitle from "../../assets/subtitle-line.webp";

const ESG = () => {
  const images = [esg1, esg2];
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  useEffect(() => {
    AOS.init({
      disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <div className='esg section__padding' id='environement-and-safety'>
      <div
        className='esg-content'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <h2 className='gradient__text'>ZeroT ESG</h2>
        <img src={subtitle} alt='' className='subtitle-line' />
        <p className='subtitle'>
          Turn your Business Data into Sustainable business value - Sustainable
          AI
        </p>
        <ul className='list'>
          <li>Increase Productivity</li>
          <li>Industrial Agnostic</li>
        </ul>
      </div>
      <div
        className='esg-image'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <img className='image transition' src={images[imageIndex]} alt='ESG' />
      </div>
    </div>
  );
};

export default ESG;
