import React, { useState, useEffect } from "react";
import "./iot.css";
import Aos from "aos";
import "aos/dist/aos.css";
import iot1 from "../../assets/pic4.webp";
import iot2 from "../../assets/pic5.webp";
import subtitle from "../../assets/subtitle-line.webp";

const IoT = () => {
  const images = [iot1, iot2];
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <div className='iot section__padding' id='internet-of-things'>
      <div
        className='iot-content'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <h2 className='gradient__text'>ZeroT IoT</h2>
        <img src={subtitle} alt='' className='subtitle-line' />
        <p className='subtitle'>
          IoT Analytics Platform that turns complex data into Real Time insights
        </p>
        <ul className='list'>
          <li>Device monitoring and data collection at the edge</li>
          <li>Continuous service delivery</li>
          <li>Predictive Analytics and making</li>
          <li>AIoT Powered Digital Twin</li>
        </ul>
      </div>
      <div
        className='iot-image'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <img className='image' src={images[imageIndex]} alt='IoT' />
      </div>
    </div>
  );
};

export default IoT;
