import React from "react";
import AnimationHomePage from "../../components/Animation/AnimationHomePage";
import ESG from "../../containers/ESG/ESG";
import Hero from "../../containers/Hero/Hero";
import IoT from "../../containers/IoT/IoT";
import OilGas from "../../containers/OilGas/OilGas";
import OpsPlatform from "../../containers/OpsPlatform/OpsPlatform";

const Home = () => {
  return (
    <>
      <AnimationHomePage />
      <Hero />
      <OpsPlatform />
      <IoT />
      <OilGas />
      <ESG />
    </>
  );
};

export default Home;
