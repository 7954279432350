import React, { useEffect } from "react";
import anu from "../../assets/anu1.webp";
import bishal from "../../assets/bishal.webp";
import neethan from "../../assets/neethan.webp";
import dishant from "../../assets/dishant.webp";
import prasad from "../../assets/prasad.webp";
import madhumita from "../../assets/madhumita.webp";
import shashi from "../../assets/shashi2.webp";
import tony from "../../assets/tony.webp";
import lavanya from "../../assets/lavanya.jpg";
import prasanna from "../../assets/prasanna.webp";
import aadesh from "../../assets/aadesh1.webp";
import anmol from "../../assets/anmol1.webp";
import sabana from "../../assets/sabana1.webp";
import sukhanya from "../../assets/sukhanya.webp";
import sahil from "../../assets/sahil11.webp";
import Aos from "aos";
import "aos/dist/aos.css";
import "./team.css";

const Team = () => {
  useEffect(() => {
    Aos.init();
  }, []);

  const leadershipMembers = [
    {
      name: "Prasanna Kishore",
      role: "Chief Operational Officer",
      linkedin: "https://www.linkedin.com/in/prasanna-kishore-6027431a2/",
      image: prasanna,
    },
    {
      name: "Anu Bama",
      role: "Chief Technology Officer",
      linkedin: "https://www.linkedin.com/in/anubama/",
      image: anu,
    },
  ];

  const teamMembers = [
    {
      name: "Sahil Survase",
      role: "Program Manager",
      linkedin: "https://www.linkedin.com/in/sahil-survase-825181249/",
      image: sahil,
    },
    {
      name: "Madhumita",
      role: "Senior Machine Learning Engineer",
      linkedin: " https://www.linkedin.com/in/madhumita-selvan-59aaa7155/",
      image: madhumita,
    },
    {
      name: "Dishant Kumar",
      role: "Senior Machine Learning Engineer",
      linkedin: "https://www.linkedin.com/in/dishant-kumar-538125139/",
      image: dishant,
    },
    {
      name: "Prasad Shinde",
      role: "Machine Learning Engineer",
      linkedin: "https://www.linkedin.com/in/prasad-shinde-30b576209/",
      image: prasad,
    },
    {
      name: "Sabana",
      role: "Machine Learning Intern",
      linkedin: "https://www.linkedin.com/in/sabana-ashraf-39809a127/",
      image: sabana,
    },
    {
      name: "Bishal Mukherjee",
      role: "Frontend Developer",
      linkedin: "https://www.linkedin.com/in/bishal-mukherjee-115a8b218/",
      image: bishal,
    },
    // {
    //   name: "Neethan Paheerathan",
    //   role: "Frontend Developer",
    //   linkedin: "https://www.linkedin.com/in/neethan-paheerathan-4b4694231/",
    //   image: neethan,
    // },
    {
      name: "Anmol Bhargava",
      role: "Software Engineer",
      linkedin: "https://www.linkedin.com/in/anmolbhargava008/",
      image: anmol,
    },
    {
      name: "Shashi Kumar",
      role: "Backend Developer",
      linkedin: "https://www.linkedin.com/in/shashi-kumar-6b309a211",
      image: shashi,
    },
    // {
    //   name: "Tony Fiori",
    //   role: "Backend Developer",
    //   linkedin: "https://www.linkedin.com/in/tony-fiori/",
    //   image: tony,
    // },
    {
      name: "Aadesh Patil",
      role: "Backend Developer",
      linkedin: "https://www.linkedin.com/in/aadesh-patil-3b8474193/",
      image: aadesh,
    },
    {
      name: "Lavanya",
      role: "DevOps Engineer",
      linkedin: "https://www.linkedin.com/in/lavanya-sivareddy-1aba0b260",
      image: lavanya,
    },
    // {
    //   name: "Sukhanya M",
    //   role: "DevOps Engineer",
    //   linkedin: "https://www.linkedin.com/in/sukhanya-m-a88874266/",
    //   image: sukhanya,
    // },
  ];

  return (
    <div
      className="team section__padding"
      data-aos="fade-down"
      data-aos-easing="ease-in-out"
      data-aos-duration="1500"
      id="team"
    >
      <div className="about">
        <div className="about__content">
          <h1 className="gradient__text">Our Team</h1>
          <p>
            Our team is a group of passionate and dedicated individuals who are
            committed to delivering the best.
          </p>
          <h2>Leadership Team</h2>
          <div
            className="team__members"
            data-aos="fade-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="1500"
          >
            {leadershipMembers.map((member, index) => (
              <div className="leadership__member" key={index}>
                <a href={member.linkedin}>
                  <img src={member.image} alt="" />
                  <h3>{member.name}</h3>
                  <p>{member.role}</p>
                </a>
              </div>
            ))}
          </div>
          
          <h2>Team Members</h2>
          <div
            className="team__members"
            data-aos="fade-up"
            data-aos-easing="ease-in-out"
            data-aos-duration="2500"
          >
            {teamMembers.map((member, index) => (
              <div className="team__member" key={index}>
                <a href={member.linkedin}>
                  <img src={member.image} alt="member" />
                  <h3>{member.name}</h3>
                  <p>{member.role}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
