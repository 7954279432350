import React, { useState } from "react";
import "./CostManagement/services.css"

import { Container, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import { VisualOps } from "./VisualOps/VisualOps";
import { CostManagement } from "./CostManagement/CostManagement";

const Services = () => {
  const navigationTabs = ["Visual Ops", "Cost Management"];
  const [selectedTab, setSelectedTab] = useState(0);

  const NAVIGATION_OBJ = {
    0: <VisualOps />,
    1: <CostManagement />,
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <div>
      <div className="section__padding">
        <Container maxWidth="sm">
          <Container maxWidth>
            <Grid container>
              <Grid item xs={12}>
                <Grid
                  container
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Tabs className="nav-tab" value={selectedTab} onChange={handleChange}>
                    {navigationTabs.map((tab, index) => (
                      <Tab
                        className="technology-tabs"
                        label={
                          <Typography
                            className="tab-font"
                          >
                            {tab}
                          </Typography>
                        }
                        disableRipple
                        key={index}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
      {NAVIGATION_OBJ[selectedTab]}
    </div>
  );
};

export default Services;

// import React from "react";
// import "./services.css";
// import process from "../../assets/process.webp";
// import mechanical from "../../assets/mechanical.webp";
// import piping from "../../assets/piping.webp";
// import instrumentation from "../../assets/instrumentation.webp";
// import electrical from "../../assets/electrical.webp";
// import structural from "../../assets/structural.webp";
// import civil from "../../assets/civil.webp";
// import { ServicesCarousel } from "./ServicesCarousel";

// const Services = () => {
//   function myFunction() {
//     var dots = document.getElementById("dots");
//     var moreText = document.getElementById("more");
//     var btnText = document.getElementById("myBtn");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction2() {
//     var dots = document.getElementById("dots2");
//     var moreText = document.getElementById("more2");
//     var btnText = document.getElementById("myBtn2");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction3() {
//     var dots = document.getElementById("dots3");
//     var moreText = document.getElementById("more3");
//     var btnText = document.getElementById("myBtn3");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction4() {
//     var dots = document.getElementById("dots4");
//     var moreText = document.getElementById("more4");
//     var btnText = document.getElementById("myBtn4");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction5() {
//     var dots = document.getElementById("dots5");
//     var moreText = document.getElementById("more5");
//     var btnText = document.getElementById("myBtn5");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction6() {
//     var dots = document.getElementById("dots6");
//     var moreText = document.getElementById("more6");
//     var btnText = document.getElementById("myBtn6");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   function myFunction7() {
//     var dots = document.getElementById("dots7");
//     var moreText = document.getElementById("more7");
//     var btnText = document.getElementById("myBtn7");

//     if (dots.style.display === "none") {
//       dots.style.display = "inline";
//       btnText.innerHTML = "Read more";
//       moreText.style.display = "none";
//     } else {
//       dots.style.display = "none";
//       btnText.innerHTML = "Read less";
//       moreText.style.display = "inline";
//     }
//   }
//   return (
//     <div className='services__container section__padding' id='services'>
//       <h1 className='gradient__text'>Services</h1>

//       {/* container-1 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_left'
//         id='process'
//       >
//         <div className='services__content'>
//           <h2>Process</h2>
//           <ol>
//             <li>
//               Process Design Basis
//             </li>
//             <li>
//               Process Descriptions
//             </li>
//             <li>
//               Process Flow Diagrams
//             </li>
//             <li>
//               Utility Balance
//             </li>
//             <li>
//               Process Safe Guarding Diagrams
//               <span id="dots">...</span> &nbsp; &nbsp;
//               <span onClick={myFunction} id="myBtn">Read more...</span> &nbsp;
//             </li>

//             <span id="more">
//               <li className="li-6">
//                 Material selection diagram
//               </li>
//               <li>
//                 Operating and Control Philosophy
//               </li>
//               <li>
//                 Shutdown Philosophy
//               </li>
//               <li>
//                 Relief and Blow down Philosophy
//               </li>
//               <li>
//                 Pre-commissioning and Commissioning philosophy
//               </li>
//               <li>
//                 Isolation & Sparing Philosophy
//               </li>
//               <li>
//                 Process & Utility System P&ID’s
//               </li>
//               <li>
//                 Cause & Effect Diagram
//               </li>
//               <li>
//                 Equipment Sizing Calculations
//               </li>
//               <li>
//                 Data Sheets of Equipment
//               </li>
//               <li>
//                 Process Equipment Data Sheets
//               </li>
//               <li>
//                 Line Designation Tables
//               </li>
//               <li>
//                 Depressurization studies of plant, equipment and piping
//               </li>
//               <li>
//                 Blow down valve and Relief valve sizing calculation
//               </li>
//               <li>
//                 Relief valve sizing calculations
//               </li>
//               <li>
//                 HAZOP close out report
//               </li>
//               <li>
//                 Design Review and Report
//               </li>
//               <li>
//                 Pre commissioning and commissioning Procedures
//               </li>
//               <li>
//                 Plant Operating Manual
//               </li>
//               <span onClick={myFunction} id="myBtn">Read Less</span> &nbsp;

//             </span> &nbsp;

//           </ol>

//         </div>
//         <div className='services__image'>
//           <img src={process} alt='technology' />
//         </div>
//       </div>

//       {/* container-2 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_right'
//         id='mechanical'
//       >
//         <div className='services__content'>
//           <h2>Mechanical</h2>
//           <ol>
//             <li>
//               Design Basis
//             </li>
//             <li>
//               Design Calculations of Static Equipment’s as per applicable codes & standards (ASME Sec
//               VIII/ API 650/ API620, TEMA)
//             </li>
//             <li>
//               Detailed Fabrication drawings for all Static equipment’s (Tanks, Vessels, Columns, Heat
//               Exchangers, Stacks)
//             </li>
//             <li>
//               Mechanical Equipment Specifications
//             </li>
//             <li>
//               Technical specifications, Tender Bid Evaluation and Purchase Recommendations
//               <span id="dots2">...</span> &nbsp;
//               <span onClick={myFunction2} id="myBtn2">Read more...</span> &nbsp;
//             </li>

//             <span id="more2">
//               <li className="li-6">
//                 Equipment List
//               </li>
//               <li>
//                 Mechanical Data Sheets
//               </li>
//               <li>
//                 Insulation and Painting Specifications
//               </li>
//               <li>
//                 Material Take Off
//               </li>
//               <li>
//                 Erection Tender Specifications
//               </li>
//               <li>
//                 Vendor Data / Drawing Review
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={mechanical} alt='mentor' />
//         </div>
//       </div>

//       {/* container-3 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_left'
//         id='piping'
//       >
//         <div className='services__content'>
//           <h2>Piping</h2>
//           <ol>
//             <li>
//               Design Basis
//             </li>
//             <li>
//               3D Modelling and Review sessions
//             </li>
//             <li>
//               Pipe Thickness Calculation
//             </li>
//             <li>
//               Schlumberger-Private
//             </li>
//             <li>
//               Piping Material Specification
//               <span id="dots3">...</span> &nbsp;
//               <span onClick={myFunction3} id="myBtn3">Read more...</span> &nbsp;
//             </li>

//             <span id="more3">
//               <li className="li-6">
//                 Data sheets
//               </li>
//               <li>
//                 Material Requisitions
//               </li>
//               <li>
//                 Technical Bid Evaluation
//               </li>
//               <li>
//                 Vendor Document Review
//               </li>
//               <li>
//                 Flexibility Check / Stress Analysis
//               </li>
//               <li>
//                 Material Take off
//               </li>
//               <li>
//                 Plot Plan / Equipment Layout / Key Plan
//               </li>
//               <li>
//                 Piping General Arrangement, Sections and Details
//               </li>
//               <li>
//                 Pipe Supports Design
//               </li>
//               <li>
//                 Isometrics
//               </li>
//               <li>
//                 As Built Drawings
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={piping} alt='travel' />
//         </div>
//       </div>

//       {/* container-4*/}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_right'
//         id='instrumentation'
//       >
//         <div className='services__content'>
//           <h2>Instrumentation</h2>

//           <ol>
//             <li>
//               Design Basis
//             </li>
//             <li>
//               Scope of work for Instrumentation
//             </li>
//             <li>
//               Specification for DCS / ESD / F & G systems
//             </li>
//             <li>
//               Specifications for Instruments
//             </li>
//             <li>
//               Instrument Specification for Vendor Packages & Equipment
//               <span id="dots4">...</span> &nbsp;
//               <span onClick={myFunction4} id="myBtn4">Read more...</span> &nbsp;
//             </li>

//             <span id="more4">
//               <li className="li-6">
//                 Instrument Index
//               </li>
//               <li>
//                 Instrument Data sheets
//               </li>
//               <li>
//                 Input / Output Schedule for Control system
//               </li>
//               <li>
//                 Set points, Alarm & Trip Register
//               </li>
//               <li>
//                 Instrument – Electrical Interfaces
//               </li>
//               <li>
//                 Instrument Installation specification
//               </li>
//               <li>
//                 Control system Architectural drawing
//               </li>
//               <li>
//                 Cause & Effect Diagram
//               </li>
//               <li>
//                 Control room / Equipment Room layout
//               </li>
//               <li>
//                 Instrument Location Layout
//               </li>
//               <li>
//                 Cable Block Diagram
//               </li>
//               <li>
//                 Cable Schedule
//               </li>
//               <li>
//                 Junction box Schedule
//               </li>
//               <li>
//                 Instrument 3D Modelling
//               </li>
//               <li>
//                 Instrument Cable routing diagram
//               </li>
//               <li>
//                 Instrument Interconnection Diagram
//               </li>
//               <li>
//                 Instrument loop Diagram
//               </li>
//               <li>
//                 Instrument Hook-up / Installation Diagram
//               </li>
//               <li>
//                 Bulk Material Take off
//               </li>
//               <li>
//                 Material requisition / Technical Bid Evaluation / Purchase Requisition
//               </li>
//               <li>
//                 Vendor Data Review
//               </li>
//               <li>
//                 SIL closeout
//               </li>
//               <li>
//                 As-built update
//                 <span onClick={myFunction4} id="myBtn4">--</span> &nbsp;
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={instrumentation} alt='tools-for-work' />
//         </div>
//       </div>

//       {/* container-5 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_left'
//         id='electrical'
//       >
//         <div className='services__content'>
//           <h2>Electrical</h2>
//           <ol>
//             <li>
//               Electrical Design Basis & specifications
//             </li>
//             <li>
//               Load List
//             </li>
//             <li>
//               Electrical Equipment Sizing (Generator, Transformer, UPS etc.)
//             </li>
//             <li>
//               Cable Sizing & schedule
//             </li>
//             <li>
//               Cable Tray Sizing
//               <span id="dots5">...</span> &nbsp;
//               <span onClick={myFunction5} id="myBtn5">Read more...</span> &nbsp;
//             </li>
//             <span id="more5">
//               <li className="li-6">
//                 3D Modelling
//               </li>
//               <li>
//                 Earthing Calculation
//               </li>
//               <li>
//                 Lightning Protection Designing
//               </li>
//               <li>
//                 Lighting Design Calculation using various Softwares like Chalmit & Dialux
//               </li>
//               <li>
//                 Data Sheets
//               </li>
//               <li>
//                 Design of Bus Duct System
//               </li>
//               <li>
//                 Cathodic Protection Designing
//               </li>
//               <li>
//                 Heat Tracing
//               </li>
//               <li>
//                 Preparation of MTO
//               </li>
//               <li>
//                 Material Requisition
//               </li>
//               <li>
//                 Technical Bid Evaluation
//               </li>
//               <li>
//                 Vendor Document Review
//               </li>
//               <li>
//                 Single Line Diagram
//               </li>
//               <li>
//                 Control Schematics
//               </li>
//               <li>
//                 Hazardous area classification
//               </li>
//               <li>
//                 Cable routing
//               </li>
//               <li>
//                 Lighting Layout
//               </li>
//               <li>
//                 Earthing layout
//               </li>
//               <li>
//                 Earthy Grid Design
//               </li>
//               <li>
//                 Lightning Protection Layout
//               </li>
//               <li>
//                 Installation details
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={electrical} alt='mentor' />
//         </div>
//       </div>

//       {/* container-6 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_right'
//         id='structural'
//       >
//         <div className='services__content'>
//           <h2>Structural</h2>
//           <ol>
//             <li>
//               Scope of work
//             </li>
//             <li>
//               Specifications
//             </li>
//             <li>
//               Basic design drawings
//             </li>
//             <li>
//               MTO’s
//             </li>
//             <li>
//               Deck sizing and layout
//               <span id="dots6">...</span> &nbsp;
//               <span onClick={myFunction6} id="myBtn6">Read more...</span> &nbsp;
//             </li>
//             <span id="more6">
//               <li className="li-6">
//                 Fabrication and installation procedures
//               </li>
//               <li>
//                 Material specification for Deck and Jackets
//               </li>
//               <li>
//                 SACS modelling and various analysis (INPLACE, LIFT & LAUNCH, Loadout,
//                 Transportation etc..)
//               </li>
//               <li>
//                 Detailed analysis reports
//               </li>
//               <li>
//                 Deck and Jacket drawings
//               </li>
//               <li>
//                 Primary, Secondary, territory & Misc Drawings
//               </li>
//               <li>
//                 Equipment support design and drawings
//               </li>
//               <li>
//                 Pipe support validation and drawings for special pipe supports
//               </li>
//               <li>
//                 Installation aids - Design and drawings
//               </li>
//               <li>
//                 As- Built drawings
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={structural} alt='travel' />
//         </div>
//       </div>

//       {/* container-7 */}
//       <div
//         data-aos='fade-down'
//         data-aos-easing='linear'
//         data-aos-duration='2000'
//         className='services__container_left'
//         id='civil'
//       >
//         <div className='services__content'>
//           <h2>Civil</h2>
//           <ol>
//             <li>
//               Specifications
//             </li>
//             <li>
//               Site grading drawings
//             </li>
//             <li>
//               Dynamic analysis and design.
//             </li>
//             <li>
//               Schlumberger-Private
//             </li>
//             <li>
//               Calculation reports.
//               <span id="dots7">...</span> &nbsp;
//               <span onClick={myFunction7} id="myBtn7">Read more...</span> &nbsp;
//             </li>
//             <span id="more7">
//               <li className="li-6">
//                 Pipe rack Foundation design and drawings
//               </li>
//               <li>
//                 Equipment foundation design and drawings
//               </li>
//               <li>
//                 Underground layout design and drawings
//               </li>
//               <li>
//                 Design of beams, Columns, Roofs & floors.
//               </li>
//               <li>
//                 Steel drawing for truss details, bracings etc.
//               </li>
//               <li>
//                 3D modelling activities.
//               </li>
//               <li>
//                 MTO’s
//               </li>
//               <li>
//                 As- Built drawings & Documentation
//               </li>
//             </span> &nbsp;
//           </ol>
//         </div>
//         <div className='services__image'>
//           <img src={civil} alt='travel' />
//         </div>
//       </div>
//       <ServicesCarousel />
//     </div>

//   );
// };

// export default Services;
