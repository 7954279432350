import React from "react";
import "./solutions.css";
import SolutionArticle from "../../components/Solution/SolutionArticle";
import forecasting from "../../assets/forecasting.webp";
import energy from "../../assets/energy-management.webp";
import asset from "../../assets/asset-management.webp";
import cost from "../../assets/cost-management.webp";
import performance from "../../assets/performance-optimization.webp";
import predictive from "../../assets/predictive-maintenance.webp";
import intelligent from "../../assets/intelligent-detection.webp";
import prescriptive from "../../assets/prescriptive-insights.webp";
import recommendation from "../../assets/recommendation-system.webp";
import infrastructure from "../../assets/infrastructure-map.webp";
import ai from "../../assets/ai.webp";
import zero from "../../assets/zero-touch-platform.webp";
import devops from "../../assets/devops.webp";
import system from "../../assets/system-integration.webp";
import digital from "../../assets/digital-twin.webp";
import carbon from "../../assets/carbon-management.webp";
import AnimationSolutionPage from "../../components/Animation/AnimationSolutionPage";

const solution = [
  {
    imgUrl: forecasting,
    title: "Forecasting and Planning",
    text: "Forecasting and planning are important elements of business management, as well as in other areas such as finance, economics, and weather forecasting. Forecasting involves using data from the past and present to make predictions about the future. This can be done using a variety of techniques, such as time series analysis, econometrics, and machine learning. Forecasts can be used for a wide range of applications, such as demand forecasting, financial forecasting, and weather forecasting.",
    textDetails:
      "Forecasting and planning are important elements of business management, as well as in other areas such as finance, economics, and weather forecasting. Forecasting involves using data from the past and present to make predictions about the future. This can be done using a variety of techniques, such as time series analysis, econometrics, and machine learning. Forecasts can be used for a wide range of applications, such as demand forecasting, financial forecasting, and weather forecasting.",
    detailsTwo:
      "Planning is the process of developing a strategy or course of action to achieve a specific goal or set of goals. It is a critical element of decision-making, as it helps organizations to anticipate and prepare for future challenges and opportunities. Planning often involves identifying the resources that will be needed, as well as developing a schedule and budget. Forecasting and planning are closely related, as forecasts are often used as inputs for planning. For example, a sales forecast can be used to plan inventory levels, and a financial forecast can be used to plan the budget for the upcoming year.",
    detailsThree:
      "There are also advanced forecasting techniques such as multivariate forecasting where multiple variables are taken into account to predict the outcome. It is also important to take into account the uncertainty and risks involved with any forecast, and to have a plan in place to respond to potential deviations from the forecast.",
  },
  {
    imgUrl: energy,
    title: "Energy Management",
    text: "Energy management refers to the systematic approach to the planning, implementation, monitoring, and analysis of energy usage in order to meet specific goals and objectives. This can include both reducing energy consumption and increasing the use of renewable energy sources. Energy management can be applied at various levels, such as in buildings, industrial facilities, and entire communities. It can also include energy conservation measures, energy efficiency improvements, and the use of energy storage systems. The goal of energy management is often to reduce energy costs and environmental impact, while ensuring a reliable and sustainable energy supply.",
    textDetails:
      "Energy management refers to the systematic approach to the planning, implementation, monitoring, and analysis of energy usage in order to meet specific goals and objectives. This can include both reducing energy consumption and increasing the use of renewable energy sources. Energy management can be applied at various levels, such as in buildings, industrial facilities, and entire communities. It can also include energy conservation measures, energy efficiency improvements, and the use of energy storage systems. The goal of energy management is often to reduce energy costs and environmental impact, while ensuring a reliable and sustainable energy supply.",
  },
  {
    imgUrl: asset,
    title: "Asset Management",
    text: "Asset management refers to the process of managing assets in order to meet specific financial and operational goals and objectives. The assets in question can be physical assets, such as buildings, equipment, and infrastructure, or financial assets, such as stocks, bonds, and real estate.",
    textDetails:
      "Asset management refers to the process of managing assets in order to meet specific financial and operational goals and objectives. The assets in question can be physical assets, such as buildings, equipment, and infrastructure, or financial assets, such as stocks, bonds, and real estate.",
    detailsTwo:
      "In general, asset management involves a number of different activities, such as identifying and classifying assets, setting performance and asset management targets, developing and implementing asset management plans, monitoring and reporting on the performance of assets, and making decisions about the acquisition, operation, maintenance, and disposal of assets.",
    detailsThree:
      "The goal of asset management is often to optimize the performance and value of assets, while minimizing risks and costs. In the context of physical assets, this can include tasks such as scheduling maintenance and repairs, forecasting future needs, and evaluating the costs and benefits of different options. In the context of financial assets, it can include such tasks as allocating investments, managing risk and returns, and monitoring portfolio performance. Asset management can be applied in many different settings, including businesses, governments, and non-profit organizations. There are also professional Asset management companies which offers their service to manage assets for their clients such as institutional investors or high net worth individuals",
  },
  {
    imgUrl: cost,
    title: "Cost Management",
    text: "Cost management refers to the process of planning and controlling the budget of a project, program, or organization in order to meet specific financial goals and objectives. It is a systematic approach to identifying, measuring, analyzing, and controlling the costs incurred by an organization or project.",
    textDetails:
      "Cost management refers to the process of planning and controlling the budget of a project, program, or organization in order to meet specific financial goals and objectives. It is a systematic approach to identifying, measuring, analyzing, and controlling the costs incurred by an organization or project.",
    detailsTwo:
      "The primary goal of cost management is to ensure that resources are used efficiently and effectively, while also minimizing waste and controlling costs. This can include activities such as estimating costs, creating budgets, tracking actual costs, and identifying and addressing variances between actual and budgeted costs.",
    detailsThree:
      "Cost management can be applied in many different settings, including businesses, governments, and non-profit organizations. In the context of businesses, cost management may involve determining the most cost-effective way to produce goods or services, and identifying ways to reduce costs without sacrificing quality or performance. In the context of governments and non-profit organizations, cost management may focus on identifying and controlling costs associated with specific programs or initiatives. Cost management techniques includes various tools and methods such as budgeting, cost-benefit analysis, activity-based costing, cost-volume-profit analysis, break-even point analysis, and cost estimation.",
  },
  {
    imgUrl: performance,
    title: "Performance Optimization",
    text: "Performance optimization refers to the process of improving the performance of a system or organization in order to meet specific goals and objectives. The term can refer to a wide range of activities, depending on the context and the specific system or organization being optimized.",
    textDetails:
      "Performance optimization refers to the process of improving the performance of a system or organization in order to meet specific goals and objectives. The term can refer to a wide range of activities, depending on the context and the specific system or organization being optimized.",
    detailsTwo:
      "In general, performance optimization involves identifying and analyzing performance bottlenecks or inefficiencies, and then implementing changes or improvements in order to overcome these issues and achieve desired results. This can include activities such as measuring and monitoring performance, identifying areas for improvement, developing and implementing optimization strategies, and evaluating the effectiveness of these strategies.",
    detailsThree:
      "Performance optimization can be applied in many different settings, including in software systems, manufacturing processes, logistics and supply chains, financial systems and organizations, and more. It can be used to optimize the performance of a single system or process, or to optimize the performance of an entire organization. The techniques used for performance optimization can range from simple process improvement techniques such as Lean Six Sigma to complex mathematical models and machine learning. One of the key challenges in performance optimization is finding the right balance between complexity and simplicity in the techniques and models used, so that the results are accurate and actionable.",
  },
  {
    imgUrl: predictive,
    title: "Predictive Maintenance",
    text: "Predictive maintenance (PdM) refers to a maintenance strategy that utilizes data and analysis to predict when equipment or machinery is likely to fail, so that maintenance can be scheduled in advance, before the equipment actually fails. This approach is intended to improve equipment performance and reduce downtime, as well as to minimize the need for costly, unplanned repairs or replacements.",
    textDetails:
      "Predictive maintenance (PdM) refers to a maintenance strategy that utilizes data and analysis to predict when equipment or machinery is likely to fail, so that maintenance can be scheduled in advance, before the equipment actually fails. This approach is intended to improve equipment performance and reduce downtime, as well as to minimize the need for costly, unplanned repairs or replacements.",
    detailsTwo:
      "Predictive maintenance utilizes various tools and technologies such as sensors, IoT devices, and machine learning algorithms to monitor and analyze data from equipment in real-time. This data can include temperature, vibration, and other metrics, and is used to detect patterns or anomalies that may indicate an impending failure. This can allow maintenance teams to schedule repairs and replacement of equipment components before they fail, reducing the risk of costly downtime or equipment failure.",
    detailsThree:
      "Predictive maintenance can be used in a wide range of industries, such as manufacturing, transportation, and energy. It can be applied to equipment and machinery of all types, from large industrial machines and vehicles to small, specialized equipment. It can also be applied to both mechanical and electronic equipment. Implementing predictive maintenance requires a combination of knowledge of mechanical systems, sensor technology, and data analytics, and can have a significant impact on the bottom line of a business by reducing the cost of downtime and maintenance.",
  },
  {
    imgUrl: intelligent,
    title: "Intelligent Detection",
    text: "Intelligent detection is a method of using technology, such as artificial intelligence (AI) and machine learning, to automatically detect patterns, anomalies, or specific conditions within data sets.",
    textDetails:
      "Intelligent detection is a method of using technology, such as artificial intelligence (AI) and machine learning, to automatically detect patterns, anomalies, or specific conditions within data sets. This can be used in a variety of applications, such as security systems, medical diagnostics, and industrial automation. The goal of intelligent detection is to improve the accuracy and efficiency of identifying important information and make more effective decisions by automating the detection process.",
  },
  {
    imgUrl: prescriptive,
    title: "Prescriptive Insights",
    text: "Prescriptive Insights is a term that refers to the use of advanced analytics and artificial intelligence to identify not only what has happened in a given situation, but also to predict what will happen in the future, and provide recommendations on what actions to take in order to achieve a desired outcome.",
    textDetails:
      "Prescriptive insights is a term that refers to the use of advanced analytics and artificial intelligence to identify not only what has happened in a given situation, but also to predict what will happen in the future, and provide recommendations on what actions to take in order to achieve a desired outcome. It is often used in business and industry to optimize operations, identify new opportunities and improve decision making. Prescriptive analytics goes beyond descriptive and predictive analytics, providing actionable advice for business leaders to take informed decisions.",
  },

  {
    imgUrl: recommendation,
    title: "Recommandation System",
    text: "A recommendation system is a type of algorithm that is designed to predict what a user might be interested in, based on their past behavior and preferences.",
    textDetails:
      "A recommendation system is a type of algorithm that is designed to predict what a user might be interested in, based on their past behavior and preferences. It is often used in online systems such as e-commerce websites, streaming services, and social media platforms to provide personalized recommendations to users. The recommendations can be for products, content, friends, or other items that the system predicts the user will like or find useful. The goal of a recommendation system is to improve the user's experience by providing them with tailored recommendations, and in some cases, it also helps the businesses to boost their sales and user engagement.",
  },
  {
    imgUrl: infrastructure,
    title: "Infrastructure Map",
    text: "An infrastructure map is a graphical representation of the physical components and their interconnections that make up a particular infrastructure.",
    textDetails:
      "An infrastructure map is a graphical representation of the physical components and their interconnections that make up a particular infrastructure. It can be used to understand the layout and organization of an infrastructure, as well as to identify potential vulnerabilities or bottlenecks. The map can include information such as the location of key assets, the types of equipment and technology used, and the connections between different components. It can also show the dependencies and relationships between different parts of the infrastructure, and help to identify areas where maintenance or upgrades may be needed. The primary purpose of an infrastructure map is to provide a clear and comprehensive overview of the infrastructure system and allow for better management and planning.",
  },
  {
    imgUrl: ai,
    title: "AI for IT Operations",
    text: "AI for IT Operations (AIOps) is the application of artificial intelligence (AI) and machine learning techniques to the management and optimization of IT systems and infrastructure.",
    textDetails:
      "AI for IT Operations (AIOps) is the application of artificial intelligence (AI) and machine learning techniques to the management and optimization of IT systems and infrastructure. It involves using data from various IT systems and devices to gain real-time insights into the performance of the infrastructure and identify any potential issues. AIOps solutions can automate the detection, diagnosis, and resolution of IT incidents, helping to improve the availability, performance, and security of IT systems. It also provide the ability to analyze large and complex data sets, in order to identify patterns, trends and anomalies that could indicate emerging problems, and also taking automated actions to prevent them from becoming critical incidents. AIOps is used to optimize and automate IT operations in order to increase efficiency, reduce costs and minimize downtime.",
  },
  {
    imgUrl: zero,
    title: "Zero Touch Platform",
    text: "A Zero Touch Platform (ZTP) is a type of automation platform that is designed to provision, configure and maintain network devices and equipment with minimal or no human intervention.",
    textDetails:
      "A Zero Touch Platform (ZTP) is a type of automation platform that is designed to provision, configure and maintain network devices and equipment with minimal or no human intervention. This is achieved by automating the entire process of device deployment, configuration, and maintenance, as well as providing a centralized management system that can be used to monitor and control the entire network infrastructure.",
    detailsTwo:
      "The idea behind Zero-Touch provisioning is to reduce the need for manual configuration, provisioning, and maintenance of the devices. Once the device is connected to the network, it will automatically configure itself, download the necessary software and firmware and join the appropriate network automatically. ZTP can also provide real-time telemetry information and analytics to the system administrator to be more proactive with the maintenance and management of the infrastructure.",
    detailsThree:
      "The goal of a Zero Touch Platform is to reduce the human error and time taken for configuring devices, increase the speed of network deployment, lower operational costs and enhance the security of the network infrastructure.",
  },
  {
    imgUrl: devops,
    title: "MLOps/Edge DevOps",
    text: "MLOps and Edge DevOps are related practices that focus on the development, deployment and management of machine learning (ML) models and Edge computing respectively.",
    textDetails:
      "MLOps (Machine Learning Operations) and Edge DevOps are related practices that focus on the development, deployment and management of machine learning (ML) models and Edge computing respectively.",
    detailsTwo:
      "MLOps is a set of practices and tools that enables the collaboration between data scientists and IT operations teams to streamline the process of building, testing, deploying, and managing machine learning models in production. This includes automating the end-to-end ML lifecycle, from data pre-processing to model deployment, monitoring and maintenance. MLOps helps to make the ML development process more efficient, reliable, and reproducible, and also promote best practices for security, compliance, and governance of machine learning models.",
    detailsThree:
      "Edge DevOps is a set of practices and tools that enables the development, deployment and management of applications that are deployed on edge devices, near the data source. The goal of Edge DevOps is to facilitate the management of distributed infrastructure, ensure security and compliance across the entire infrastructure, and improve the reliability and scalability of edge deployments. It's basically a way to automate software development and IT operations for edge computing. Both MLOps and Edge DevOps focus on the automation of the processes and the integration of the best practices for the development and management of the technology, to improve the overall performance, scalability and security of the systems.",
  },
  {
    imgUrl: system,
    title: "System Integrations",
    text: "System integration is the process of bringing together different components or subsystems to form a cohesive and functional system.",
    textDetails:
      "System integration is the process of bringing together different components or subsystems to form a cohesive and functional system. The components may include hardware, software, and data, and can come from a variety of sources, such as different vendors, open-source projects, or in-house development. The goal of system integration is to ensure that all of the different parts work together seamlessly and effectively, to deliver the desired functionality and performance.",
    detailsTwo:
      "System integration can be achieved through a variety of methods, such as using APIs (Application Programming Interfaces), middleware, and messaging systems, which allow different systems to communicate and share data with each other. It can also involve the development of custom connectors, plug-ins, and scripts, to link different systems together.",
    detailsThree:
      "The end-result of a successful system integration project is a cohesive system that has all its different parts working together, and providing an enhanced experience for the user, as well as cost savings and other benefits for the organization.",
  },
  {
    imgUrl: digital,
    title: "AIoT Powered Digital Twin",
    text: "An AIoT (Artificial Intelligence of Things) powered digital twin is a virtual representation of a physical asset, device, or system that uses artificial intelligence (AI) and the Internet of Things (IoT) technology to replicate the characteristics, behaviors and functions of the real-world object.",
    textDetails:
      "An AIoT (Artificial Intelligence of Things) powered digital twin is a virtual representation of a physical asset, device, or system that uses artificial intelligence (AI) and the Internet of Things (IoT) technology to replicate the characteristics, behaviors and functions of the real-world object. It can be used to simulate, analyze, and optimize the performance and operational efficiency of the physical asset in a virtual environment.",
    detailsTwo:
      "The digital twin can be used to monitor and analyze the real-time data from the physical asset and its environment through IoT sensors, and use machine learning algorithms to make predictions and provide insights. This enables the stakeholders to identify potential issues and optimize the performance of the physical asset in a virtual environment before they occur in the real-world. It also can be used in the design and development stages of new products and systems, for testing, prototyping and optimization of their performance in a simulated environment.",
    detailsThree:
      "The goal of an AIoT powered digital twin is to optimize the performance, operational efficiency and maintenance of physical assets, and to provide a deeper understanding of their characteristics and behaviors. It provides the ability to take proactive actions and make better-informed decisions, reducing downtime, increasing the life of the equipment, and reducing maintenance costs.",
  },
  {
    imgUrl: carbon,
    title: "Carbon Management",
    text: "Carbon management is the process of identifying, measuring, and reducing the amount of carbon emissions produced by an organization, community, or society.",
    textDetails:
      "Carbon management is the process of identifying, measuring, and reducing the amount of carbon emissions produced by an organization, community, or society. It involves the implementation of strategies, policies and actions that aim to minimize the environmental impact of carbon emissions, and can include activities such as energy conservation, renewable energy generation, carbon offsetting, and the implementation of carbon pricing mechanisms.",
    detailsTwo:
      "Carbon management can be applied to a wide range of sectors, including energy production, transportation, industry, and agriculture. It often focuses on the reduction of greenhouse gas emissions, in particular carbon dioxide (CO2), which is one of the main contributors to climate change. The goal is to reduce the amount of carbon emitted into the atmosphere, to slow down global warming and address the climate change, by minimizing the negative impacts of human activities on the environment and the planet.",
    detailsThree:
      "The approach of Carbon management can vary depending on the sector and the specific emissions that need to be addressed, but it generally includes the following steps: measuring and monitoring emissions, setting reduction targets, developing, and implementing reduction strategies, and continuously evaluating and reporting on progress.",
  },
];

const trimText = (text) => {
  let words = text.split(" ");
  if (words.length > 21) {
    text = words.slice(0, 21).join(" ") + "...";
  }
  return text;
};

const Solutions = () => {
  return (
    <div className='solutions section__padding'>
      <h1 className='gradient__text'>Solutions</h1>
      <div className='solutions-container'>
        {solution.map((item, index) => (
          <SolutionArticle
            key={index}
            imgUrl={item.imgUrl}
            text={trimText(item.text)}
            title={item.title}
            textDetails={item.textDetails}
            detailsTwo={item.detailsTwo}
            detailsThree={item.detailsThree}
          />
        ))}
      </div>
      <AnimationSolutionPage />
    </div>
  );
};

export default Solutions;
