import React, { useState, useEffect } from "react";
import "./opsplatform.css";
import Aos from "aos";
import "aos/dist/aos.css";
import opsplatform1 from "../../assets/pic6.webp";
import opsplatform2 from "../../assets/pic7.webp";
import subtitle from "../../assets/subtitle-line.webp";

const OpsPlatform = () => {
  const images = [opsplatform1, opsplatform2];
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <div className='opsplatform section__padding' id='technology'>
      <div
        className='opsplatform-content'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <h2 className='gradient__text'>ZeroT OpsPlatform</h2>
        <img src={subtitle} alt='' className='subtitle-line' />
        <p className='subtitle'>
          Hassle-free Multi Cloud resource deployments, Compute, Edge and IoT
          based business outcome
        </p>
        <ul className='list'>
          <li>
            Get your focus back to application rather than operations and
            infrastructure
          </li>
          <li>Cognitive Management of Cost and Performance</li>
          <li>Visual Ops Rather than Old school code</li>
          <li>App aware infrastructure that autoscale up/down</li>
          <li>Industry First Visual Infrastructure</li>
          <li>Automated Governance</li>
          <li>MLOps/Edge Devops</li>
        </ul>
      </div>
      <div
        className='opsplatform-image'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <img className='image' src={images[imageIndex]} alt='OpsPlatform' />
      </div>
    </div>
  );
};

export default OpsPlatform;
