import React from "react";
import "./animation.css";
import variant1 from "../../assets/variant1.webp";
import variant2 from "../../assets/variant2.webp";
import variant3 from "../../assets/variant3.webp";
import variant4 from "../../assets/variant4.webp";

const AnimationResourcePage = () => {
  return (
    <>
      <div className='variant1'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant2'>
        <img src={variant2} alt='' />
      </div>
      <div className='variant3'>
        <img src={variant3} alt='' />
      </div>
      <div className='variant4'>
        <img src={variant4} alt='' />
      </div>
    </>
  );
};

export default AnimationResourcePage;
