import React from "react";
import "./animation.css";
import variant1 from "../../assets/variant1.webp";
import variant2 from "../../assets/variant2.webp";
import variant3 from "../../assets/variant3.webp";
import variant4 from "../../assets/variant4.webp";
import variant5 from "../../assets/variant5.webp";
import variant6 from "../../assets/variant6.webp";
import line1 from "../../assets/line1.webp";
import line2 from "../../assets/line2.webp";
import line3 from "../../assets/line3.webp";

const AnimationHomePage = () => {
  return (
    <>
      <div className='variant1'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant2'>
        <img src={variant2} alt='' />
      </div>
      <div className='variant3'>
        <img src={variant3} alt='' />
      </div>
      <div className='variant4'>
        <img src={variant4} alt='' />
      </div>
      <div className='variant5'>
        <img src={variant5} alt='' />
      </div>
      <div className='variant6'>
        <img src={variant6} alt='' />
      </div>
      <div className='line1'>
        <img src={line1} alt='' />
      </div>
      <div className='line2'>
        <img src={line2} alt='' />
      </div>
      <div className='line-static'>
        <img src={line3} alt='' />
      </div>
    </>
  );
};

export default AnimationHomePage;
