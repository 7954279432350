import React from "react";
import Accordion from "../../containers/Accordion/Accordion";
import AnimationHomePage from "../../components/Animation/AnimationHomePage";
import IndustriesCards from "../../containers/IndustriesCards/IndustriesCards";
import IndustriesVertical from "../../containers/IndustriesCards/industriesVertical";

const Industries = () => {
  return (
    <>
      <AnimationHomePage />
      <Accordion />
      <IndustriesVertical />
      <IndustriesCards />
    </>
  );
};

export default Industries;
