import React from "react";
import { HashLink } from "react-router-hash-link";
import "./article.css";

function replaceCapsSpace(str) {
  return str
    .replace(/[A-Z]/g, function (char) {
      return char.toLowerCase();
    })
    .replace(/ /g, "-");
}

const Article = ({ imgUrl, date, text }) => {
  return (
    <div className='blog-container_article'>
      <div className='blog-container_article-image'>
        <img src={imgUrl} alt='blog_image' />
      </div>
      <div className='blog-container_article-content'>
        <div>
          <p>{date}</p>
          <h4>{text}</h4>
        </div>

      </div>
      <HashLink style={{textAlign: 'center'}} smooth to={`/resources#blogs#${replaceCapsSpace(text)}`}>
          <p style={{fontSize: '16px'}}>Read Full  Article</p>
        </HashLink>
    </div>
  );
};

export default Article;
