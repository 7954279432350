import React, { useState, useEffect } from "react";
import "./solution.css";
import Aos from "aos";
import "aos/dist/aos.css";

const SolutionArticle = ({
  imgUrl,
  title,
  text,
  textDetails,
  detailsTwo,
  detailsThree,
}) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const replaceSpaceAndCaps = (str) => {
    return str.replace(/\s/g, "-").toLowerCase();
  };

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className='solution-container_article'
      onClick={toggleModal}
      id={replaceSpaceAndCaps(title)}
    >
      <div className='solution-container_article-image'>
        <img src={imgUrl} alt='blog_image' />
      </div>
      <div className='solution-container_article-content'>
        <h4>{title}</h4>
        <p>{text}</p>
        {modal && (
          <div
            className='modal'
            data-aos='fade-down'
            data-aos-easing='ease-out'
            data-aos-duration='1000'
          >
            <div onClick={toggleModal} className='overlay'></div>
            <div className='modal-content'>
              <div className='close-modal' onClick={toggleModal} />
              <div className='modal-content-image'>
                <img src={imgUrl} alt='' />
              </div>
              <div className='modal-content-text'>
                <h3>{title}</h3>
                <p>{textDetails}</p>
                <br />
                <p>{detailsTwo}</p>
                <br />
                <p>{detailsThree}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SolutionArticle;
