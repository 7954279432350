import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { AiOutlineSearch } from "react-icons/ai";
import { HiUserGroup } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import "./navbar.css";
import logo from "../../assets/zerotenergy.webp";
import oilgas from "../../assets/OilGas.webp";
import iot from "../../assets/internet_of_things_iot.webp";
import opsplatform from "../../assets/Technology.webp";
import esg from "../../assets/EHS.webp";
import manufacturing from "../../assets/manufacturing.webp";
import utilities from "../../assets/utilities.webp";
import mining from "../../assets/mining.webp";
import healthcare from "../../assets/healthcare.webp";
import engService from "../../assets/engineering-services.webp"
import projMang from "../../assets/project-mang.webp"
import manPower from "../../assets/manpower.webp"
import siteSupport from "../../assets/site-support.webp"
import {
  BsBuilding,
  BsJournalRichtext,
  BsCalendarEvent,
  BsFolder2,
} from "react-icons/bs";
import { RiTeamLine, RiContactsLine, RiSuitcaseLine } from "react-icons/ri";
import { FaHandsHelping } from "react-icons/fa";
import { GiArchiveResearch } from "react-icons/gi";
import { IoNewspaperOutline } from "react-icons/io5";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleSearch, setToggleSearch] = useState(false);
  return (
    <div className='navbar'>
      <NavLink to='/'>
        <div className='navbar__logo'>
          <img src={logo} alt='logo' />
        </div>
      </NavLink>
      <hr width='1' size='20' style={{ color: "#A5ABBD" }} />
      <div className='navbar__links'>
        <div className='d'>
          <p className='d-btn'>
            <NavLink to='/industries'>
              Industries
              <MdKeyboardArrowDown />
            </NavLink>
          </p>
          <div className='d-content'>
            <div className='row'>
              <div className='column'>
                <HashLink to='/industries/#technology'>
                  <img src={opsplatform} alt='' />
                  <br />
                  Technology
                </HashLink>
                <HashLink to='/industries/#internet-of-things'>
                  <img src={iot} alt='' />
                  <br />
                  Internet Of Things
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/industries/#oilgas'>
                  <img src={oilgas} alt='' />
                  <br />
                  Oil/Gas
                </HashLink>
                <HashLink to='/industries/#environement-and-safety'>
                  <img src={esg} alt='' />
                  <br />
                  Environement and safety
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/industries/#manufacturing'>
                  <img src={manufacturing} alt='' />
                  <br />
                  Manufacturing
                </HashLink>
                <HashLink to='/industries/#utilities'>
                  <img src={utilities} alt='' />
                  <br />
                  Utilities
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/industries/#mining'>
                  <img src={mining} alt='' />
                  <br />
                  Mining
                </HashLink>
                <HashLink to='/industries/#healthcare'>
                  <img src={healthcare} alt='' />
                  <br />
                  Healthcare
                </HashLink>
              </div>
            </div>
          </div>
        </div>
        <div className='d'>
          <p className='d-btn'>
            <NavLink to='/solutions'>
              Solutions <MdKeyboardArrowDown />
            </NavLink>
          </p>
          <div className='d-content'>
            <div className='row'>
              <div className='column'>
                <HashLink to='/solutions/#forecasting-and-planning'>
                  Forecasting and Planning
                </HashLink>
                <HashLink to='/solutions/#energy-management'>
                  Energy Management
                </HashLink>
                <HashLink to='/solutions/#asset-management'>
                  Asset Management
                </HashLink>
                <HashLink to='/solutions/#cost-management'>
                  Cost Management
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/solutions/#performance-optimization'>
                  Performance Optimization
                </HashLink>
                <HashLink to='/solutions/#predictive-maintenance'>
                  Predictive Maintenance
                </HashLink>
                <HashLink to='/solutions/#intelligent-detection'>
                  Intelligent Detection
                </HashLink>
                <HashLink to='/solutions/#prescriptive-insights'>
                  Prescriptive Insights
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/solutions/#recommendation-system'>
                  Recommendation system
                </HashLink>
                <HashLink to='/solutions/#infrastructure-map'>
                  Infrastructure Map
                </HashLink>
                <HashLink to='/solutions/#ai-for-it-operations'>
                  AI for IT Operations
                </HashLink>
                <HashLink to='/solutions/#zero-touch-platform'>
                  Zero Touch Platform
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/solutions/#mlops-edge-devops'>
                  MLOps/Edge DevOps
                </HashLink>
                <HashLink to='/solutions/#system-integrations'>
                  System Integrations
                </HashLink>
                <HashLink to='/solutions/#aiot-powered-digital-twin'>
                  AIoT Powered Digital Twin
                </HashLink>
                <HashLink to='/solutions/#carbon-management'>
                  Carbon Management
                </HashLink>
              </div>
            </div>
          </div>
        </div>
        {/* <p>
          <NavLink to='/technology'>Technology</NavLink>
        </p> */}






        <div className='d'>
          <p className='d-btn'>
            <NavLink to='/technology'>
              Technology <MdKeyboardArrowDown />
            </NavLink>
          </p>
          <div className='d-content'>
            <div className='row'>
              <div id="inner-service" className='column'>
                <div className="dropdown">
                  {/* <HashLink to='/services/#engineering'>
                  <img src={engService} alt='' />
                  <br />
                    Engineering Services <MdKeyboardArrowDown />
                  </HashLink> */}
                  {/* <div className="dropdown-content">
                    <HashLink to="/services/#process">Process</HashLink>
                    <HashLink to="/services/#mechanical">Mechanical</HashLink>
                    <HashLink to="/services/#piping">Piping</HashLink>
                    <HashLink to="/services/#instrumentation">Instrumentation</HashLink>
                    <HashLink to="/services/#electrical">Electrical</HashLink>
                    <HashLink to="/services/#stuctural">Structural</HashLink>
                    <HashLink to="/services/#civil">Civil</HashLink>
                  </div> */}
                </div>
              </div>
              
              {/* <div className='column'>
                <HashLink to='/services/#man-power'>
                  <img src={manPower} alt='' />
                  <br />
                  Manpower Supply
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/services/#project-man'>
                  <img src={projMang} alt='' />
                  <br />
                  Project Managment
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/services/#site-support'>
                  <img src={siteSupport} alt='' />
                  <br />
                  Site Service Support
                </HashLink>
              </div> */}
            </div>
          </div>
        </div>

        <div className='d'>
          <p className='d-btn'>
            <NavLink to='/resources'>
              Resources <MdKeyboardArrowDown />
            </NavLink>
          </p>
          <div className='d-content'>
            <div className='row'>
              <div className='column'>
                <HashLink to='/resources/#blogs'>
                  <BsJournalRichtext /> &nbsp; Blogs
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/resources/#events'>
                  <BsCalendarEvent /> &nbsp; Events
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/resources/#case-studies'>
                  <BsFolder2 />
                  &nbsp; Case Studies
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/resources/#guides'>
                  <IoNewspaperOutline /> &nbsp; Guides
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/resources/#research'>
                  <GiArchiveResearch /> &nbsp; Research
                </HashLink>
              </div>
            </div>
          </div>
        </div>
        <div className='d'>
          <p className='d-btn'>
            <NavLink to='/about'>
              About <MdKeyboardArrowDown />
            </NavLink>
          </p>
          <div className='d-content'>
            <div className='row'>
              <div className='column'>
                <HashLink to='/about#company'>
                  <BsBuilding />
                  &nbsp; Company
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/about#team'>
                  <RiTeamLine /> &nbsp; Our Team
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/about#culture'>
                  <FaHandsHelping /> &nbsp; Culture
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/about#careers'>
                  <RiSuitcaseLine /> &nbsp; Careers
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/about#testimonials'>
                  <HiUserGroup /> &nbsp; Testimonials
                </HashLink>
              </div>
              <div className='column'>
                <HashLink to='/about#contact'>
                  <RiContactsLine /> &nbsp; Contact Us
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr width='1' size='20' style={{ color: "#A5ABBD" }} />
      <div className='navbar__search'>
        {toggleSearch ? (
          <>
            <form>
              <input type='text' placeholder='Search zerotenergy.com' />
            </form>
            <RiCloseLine
              size={23}
              onClick={() => setToggleSearch(!toggleSearch)}
            />
          </>
        ) : (
          <AiOutlineSearch
            size={23}
            onClick={() => setToggleSearch(!toggleSearch)}
          />
        )}
      </div>
      <hr width='1' size='20' style={{ color: "#A5ABBD" }} />
      <div className='navbar__btns'>
        <button className='btn'>Get Started</button>
      </div>
      <div className='navbar-menu'>
        {toggleMenu ? (
          <RiCloseLine size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className='navbar-menu_container slide-in-top-2'>
            <div className='navbar__menu-search slide-in-top'>
              <form>
                <input type='text' placeholder='Search zerotenergy.com' />
              </form>
            </div>
            <div className='navbar-menu_container-links slide-in-top'>
              <ul>
                <li className='slide-in-top'>
                  <NavLink to='/' onClick={() => setToggleMenu(false)}>
                    Home
                  </NavLink>
                </li>
                <li className='slide-in-top'>
                  <NavLink
                    to='/industries'
                    onClick={() => setToggleMenu(false)}
                  >
                    Industries
                  </NavLink>
                </li>
                <li className='slide-in-top'>
                  <NavLink to='/solutions' onClick={() => setToggleMenu(false)}>
                    Solutions
                  </NavLink>
                </li>
                <li className='slide-in-top'>
                  <NavLink
                    to='/technology'
                    onClick={() => setToggleMenu(false)}
                  >
                    Technology
                  </NavLink>
                </li>
                <li className='slide-in-top'>
                  <NavLink to='/resources' onClick={() => setToggleMenu(false)}>
                    Resources
                  </NavLink>
                </li>
                <li className='slide-in-top'>
                  <NavLink to='/about' onClick={() => setToggleMenu(false)}>
                    About
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
