import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../Pages/Home/Home";
import About from "../Pages/About/About";
import Industries from "../Pages/Industries/Industries";
import Resources from "../Pages/Resources/Resources";
import Solutions from "../Pages/Solutions/Solutions";
import Services from "../Pages/Services/Services";

const Routers = () => {
  return (
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/industries' element={<Industries />} />
      <Route exact path='/solutions' element={<Solutions />} />
      <Route exact path='/technology' element={<Services/>} />
      {/* <Route exact path='/services' element={<Services/>} /> */}
      <Route exact path='/resources' element={<Resources />} />
      <Route exact path='/about/' element={<About />} />
    </Routes>
  );
};

export default Routers;
