import React, { useEffect } from "react";
import costmanagement from "../../../assets/c-m1.webp";
import costmanagement2 from "../../../assets/c-m2.png";
import "./services.css";

export const CostManagement = () => {
  return (
    <div>
      <div className="cost-management">
        <div className="cost__content">
          <h2>
            Are you tired of manually tracking your cloud spending and usage?
          </h2>
          <h4>
            Say hello to our cost management service which contains graphical
            data of cloud resources! With our dashboard, you can easily monitor
            your cloud spending on a daily, weekly, monthly, and yearly basis,
            and gain insights into your resource usage and cost breakdown by
            service or region.
          </h4>
          <div className="industries__image">
            <img
              className="cost-management-img"
              src={costmanagement}
              alt="technology"
            />
          </div>
          <hr className="hr" />

          <h2 style={{ textAlign: "center" }}>
             overspending on cloud services ??
          </h2>
          <h4>
            Our dashboard integrates with popular cloud providers such as AWS,
            Azure, and Google Cloud, providing you with accurate and real-time
            data that enables you to optimize your cloud spending and make
            informed decisions about your cloud infrastructure. With features
            such as customizable alerts and notifications, cost forecasting, and
            cost optimization recommendations, our dashboard is your go-to tool
            for managing your cloud costs and improving your overall cloud
            infrastructure. Take control of your cloud spending today with our
            personal dashboard for cost management data of cloud resources. Sign
            up now and see the difference it can make for your business!"
          </h4>
          <div className="industries__image">
            <img
              className="cost-management-img"
              src={costmanagement2}
              alt="technology"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
