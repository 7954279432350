import React, { useState } from "react";
import "./accordion.css";
import oilgas from "../../assets/OilGas.webp";
import iot from "../../assets/internet_of_things_iot.webp";
import opsplatform from "../../assets/Technology.webp";
import esg from "../../assets/EHS.webp";
import manufacturing from "../../assets/manufacturing.webp";
import utilities from "../../assets/utilities.webp";
import mining from "../../assets/mining.webp";
import healthcare from "../../assets/healthcare.webp";

const Accordion = () => {
  const [activePanel, setActivePanel] = useState(null);

  const handleClick = (panel) => {
    setActivePanel(panel === activePanel ? null : panel);
  };

  const panels = [
    {
      title: "Technology",
      text: "Hassle-free multi cloud resource deployments, Compute, Edge, Iot and based buisness outcome",
      image: opsplatform,
    },
    {
      title: "IoT",
      text: "IoT analytics Platform that turns complex data into real time insights",
      image: iot,
    },
    {
      title: "Oil/Gas",
      text: "Optimizing energy production in the oil/gas industry",
      image: oilgas,
    },
    {
      title: "ESG",
      text: "Turn your buisness data into sustainable buisness value - Sustainable AI",
      image: esg,
    },
    {
      title: "Manufacturing",
      text: "Optimize your buisness operations and reduce costs",
      image: manufacturing,
    },
    {
      title: "Utilities",
      text: "Optimize your buisness operations and reduce costs",
      image: utilities,
    },
    {
      title: "Mining",
      text: "Optimize your buisness operations and reduce costs",
      image: mining,
    },
    {
      title: "Healthcare",
      text: "Optimize your buisness operations and reduce costs",
      image: healthcare,
    },
  ];

  return (
    <div className='accordion-header section__padding'>
      <h1 className='gradient__text'>Industries</h1>
      <div className='accordion-container'>
        {panels.map((panel, index) => (
          <div
            className={`accordion-panel ${
              activePanel === index ? "active" : ""
            }`}
            key={index}
            onClick={() => handleClick(index)}
          >
            <img src={panel.image} alt='' />
            <div className='accordion-content' key={index}>
              <h3>{panel.title}</h3>
              <p>{panel.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
