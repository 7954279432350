import React from "react";
import { BsArrowRight } from "react-icons/bs";
import "./careers.css";

const Careers = () => {
  return (
    <div className="careers section__padding" id="careers">
      <div className="about">
        <div className="about__content">
        <h1 className="gradient__text">Careers</h1>
        <h2>To join our energetic team </h2>
        <h4>
          We love people who like to go beyond the normal call of duty and can
          think out of the box. Surprise us with your passion, intelligence,
          creativity and hard work.
        </h4>
        <h4>Please follow our official Linkedin page for Job Notifications.</h4>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/zerotenergy/jobs/"
        >
          <BsArrowRight /> &nbsp; Current Openings
        </a>
      </div>
    </div>
    </div>
  );
};

export default Careers;
