import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import remoteWork from "../../assets/remote-work.webp";
import toolsForWork from "../../assets/tools-for-work.webp";
import buddy from "../../assets/buddy.webp";
import travel from "../../assets/travel.webp";
import paidHoliday from "../../assets/paid-holiday.webp";
import help from "../../assets/help.webp";
import health from "../../assets/health.webp";
import "./culture.css";

const Culture = () => {
  useEffect(() => {
    AOS.init({
      disable: window.innerWidth < 768,
    });
  }, []);
  return (
    <div className="culture section__padding" id="culture">
      <div className="about">
        <div className="about__content">
      <h1 className="gradient__text">Culture</h1>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_left"
      >
        <div className="culture__content">
          <h2>Working remotely</h2>
          <p>
            At ZeroT , each remote team member gets the same or comparable
            resources for workspace, travel, and benefits.
            <br />
            We’re building a culture with an emphasis on autonomy, flexibility,
            empathy, and mobility. We believe this also presents an excellent
            opportunity for those who must live or prefer to live in rural
            areas, where careers in technical industries are sometimes not as
            easily available.
          </p>
        </div>
        <div className="culture__image">
          <img src={remoteWork} alt="remote-work" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_right"
      >
        <div className="culture__content">
          <h2>Tools for work</h2>
          <p>
            Each remote team member receives the same or comparable equipment
            package for their home office setup. This includes a laptop,
            monitor, stand, desk (if needed), mouse, keyboard, cords and other
            necessary accessories. Outside of this, each team member receives an
            additional budget upon joining ZeroT to be used for any extra items
            that may help them be most productive and comfortable at home.
          </p>
        </div>
        <div className="culture__image">
          <img src={toolsForWork} alt="tools-for-work" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_left"
      >
        <div className="culture__content">
          <h2>Buddy system and remote mentor</h2>
          <p>
            When starting at ZeroT, in addition to your Buddy, you’ll also be
            assigned a Remote Mentor. This will be another remote ZeroT team
            member with several years of remote work experience that you can
            rely on for advice and support regarding remote work.
          </p>
        </div>
        <div className="culture__image">
          <img src={buddy} alt="mentor" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_right"
      >
        <div className="culture__content">
          <h2>In-person gatherings</h2>
          <p>
            We believe building relationships face-to-face is critical towards
            fostering a close-knit and high-performance team. For this reason,
            ZeroT will pay for employees to travel up to two weeks a year and
            additional time as needed.
          </p>
        </div>
        <div className="culture__image">
          <img src={travel} alt="travel" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_left"
      >
        <div className="culture__content">
          <h2>Paid Time Off</h2>
          <p>
            ZeroT encourages real work-life balance for our employees, and
            actively supports each ZeroTian in creating the life they aspire to.
            We have an outstanding PTO policy, starting at 20 days off, for all
            full-time employees. We also offer 15 paid holidays, 10 days
            compassionate leave, 3 days volunteer time and more.
          </p>
        </div>
        <div className="culture__image">
          <img src={paidHoliday} alt="paid-holiday" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_right"
      >
        <div className="culture__content">
          <h2>Mental Well-being & Assistance</h2>
          <p>
            ZeroT offers well-being support on issues ranging from personal
            matters to everyday life topics through the WorkLifeMatters program.
          </p>
        </div>
        <div className="culture__image">
          <img src={help} alt="help" />
        </div>
      </div>
      <div
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="2000"
        className="culture__container_left"
      >
        <div className="culture__content">
          <h2>Paid Family/Medical Leave</h2>
          <p>
            ZeroT offers up to six weeks 100% paid leave for caring family
            members, and up to twelve weeks 100% paid leave for one’s own
            medical leave.
          </p>
        </div>
        <div className="culture__image">
          <img src={health} alt="mentor" />
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Culture;
