import React from "react";
import "./industriesCards.css";
import oilgas from "../../assets/oilgas1.webp";
import iot from "../../assets/internet_of_things_iot.webp";
import opsplatform from "../../assets/technology1.webp";
import esg from "../../assets/EHS.webp";

const industriesVertical = () => {
  return (
    <div className='industries__container section__padding' id='industries'>
      <div
        data-aos='fade-down'
        data-aos-easing='linear'
        data-aos-duration='2000'
        className='industries__container_left'
        id='technology'
      >
        <div className='industries__content'>
          <h2>Technology</h2>
          <p>
            An Ops Platform, or Operations Platform, is a term used to describe
            a set of tools and processes that are used to manage and maintain
            the infrastructure, applications, and services that make up an
            organization's IT environment. This can include tasks such as
            deployment, monitoring, scaling, and troubleshooting. An Ops
            Platform typically includes a combination of software, hardware, and
            services that work together to provide a centralized way to manage
            and automate operations. This can include things like configuration
            management, logging and monitoring, and incident response.
          </p>
        </div>
        <div className='industries__image'>
          <img src={opsplatform} alt='technology' />
        </div>
      </div>
      <div
        data-aos='fade-down'
        data-aos-easing='linear'
        data-aos-duration='2000'
        className='industries__container_right'
        id='internet-of-things'
      >
        <div className='industries__content'>
          <h2>Internet of Things</h2>
          <p>
            The Internet of Things (IoT) is the network of physical devices,
            vehicles, home appliances, and other items embedded with
            electronics, software, sensors, actuators, and connectivity which
            enables these objects to connect and exchange data. Each thing is
            uniquely identifiable through its embedded computing system but is
            able to interoperate within the existing Internet infrastructure.
            Experts estimate that the IoT will consist of about 30 billion
            objects by 2020.
          </p>
        </div>
        <div className='industries__image'>
          <img src={iot} alt='tools-for-work' />
        </div>
      </div>
      <div
        data-aos='fade-down'
        data-aos-easing='linear'
        data-aos-duration='2000'
        className='industries__container_left'
        id='oilgas'
      >
        <div className='industries__content'>
          <h2>Oil Gas</h2>
          <p>
            Oil and gas are fossil fuels that are formed from the remains of
            plants and animals that lived millions of years ago. Oil is a liquid
            hydrocarbon that is extracted from underground reservoirs and is
            primarily used as a fuel for transportation (e.g. gasoline for cars)
            and for heating and electricity generation. Gas, on the other hand,
            is primarily composed of methane and is often found in the same
            reservoirs as oil. It is used for heating, electricity generation,
            and as a feedstock for the production of chemicals.
            <br />
            {/* The oil and gas industry is responsible for the exploration,
            extraction, refinement, and distribution of these fossil fuels. It
            is a global industry with significant economic and political
            importance. The production and consumption of oil and gas have a
            significant impact on the environment, particularly in terms of
            greenhouse gas emissions and climate change. */}
          </p>
        </div>
        <div className='industries__image'>
          <img src={oilgas} alt='mentor' />
        </div>
      </div>
      <div
        data-aos='fade-down'
        data-aos-easing='linear'
        data-aos-duration='2000'
        className='industries__container_right'
        id='environement-and-safety'
      >
        <div className='industries__content'>
          <h2>Environmental, Social, and Governance</h2>
          <p>
            Environmental, Social, and Governance (ESG) refers to the three
            central factors in measuring the sustainability and societal impact
            of an investment in a company or business. Environmental factors
            include a company's impact on the natural environment, such as its
            greenhouse gas emissions, energy usage, and waste management
            practices. Social factors include a company's impact on society,
            such as its labor practices, human rights record, and community
            engagement. Governance factors include a company's leadership,
            executive pay, and internal controls and regulations.
          </p>
        </div>
        <div className='industries__image'>
          <img src={esg} alt='travel' />
        </div>
      </div>
    </div>
  );
};

export default industriesVertical;
