import React from "react";
import AnimationAboutPage from "../../components/Animation/AnimationAboutPage";
import Careers from "../../containers/Careers/Careers";
import Company from "../../containers/Company/Company";
import Contact from "../../containers/Contact/Contact";
import Culture from "../../containers/Culture/Culture";
import Team from "../../containers/Team/Team";
//import Testimonials from "../../containers/Testimonials/Testimonials";

const About = () => {
  return (
    <>
      <AnimationAboutPage />
      <Company />
      <Team />
      <Culture />
      <Careers />
      <Contact />
    </>
  );
};

export default About;
