import React from "react";
import AnimationResourcePage from "../../components/Animation/AnimationResourcePage";
import Blogs from "../../containers/Blogs/Blogs";
//import CaseStudies from "../../containers/CaseStudies/CaseStudies";
import Events from "../../containers/Events/Events";
//import Guides from "../../containers/Guides/Guides";
//import Research from "../../containers/Research/Research";

const Resources = () => {
  return (
    <>
      <AnimationResourcePage />
      <Blogs />
      <Events />
    </>
  );
};

export default Resources;
