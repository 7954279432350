import React, { useState } from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import manufacturing from "../../assets/manufacturing.webp";
import utilities from "../../assets/utilities.webp";
import mining from "../../assets/mining.webp";
import healthcare from "../../assets/healthcare.webp";
import "./industriesCards.css";

const reviews = [
  {
    title: "Manufacturing",
    image: manufacturing,
    text: "Manufacturing is the process of transforming raw materials into finished goods through the use of tools, equipment, and machinery. It involves a series of steps such as design, prototyping, production planning, assembly, testing, and quality control. The manufacturing industry is a key driver of economic growth and job creation, and it plays crucial role in shaping economy.",
  },
  {
    title: "Mining",
    image: mining,
    text: "Mining is the extraction of valuable minerals or other geological materials from the earth, usually from an ore body, lode, vein, seam, reef or placer deposits. These deposits form a mineralized package that is of economic interest to the miner. Ores recovered by mining which include metals, coal, oil shale, gemstones, limestone, chalk, dimension stone, rock salt, potash, gravel, and clay.",
  },
  {
    title: "Healthcare",
    image: healthcare,
    text: "Healthcare is the maintenance or improvement of health via the diagnosis, treatment, and prevention of disease, illness, injury, and other physical and mental impairments in people. Healthcare is delivered by health professionals in allied health fields. Physicians & associates are a part of these health professionals like Dentistry, midwifery, nursing, medicine, optometry etc.",
  },
];

const IndustriesCards = () => {
  const [activeSlide, setActiveSlide] = useState(1);

  function handlePrev() {
    if (activeSlide === 1) {
      setActiveSlide(reviews.length);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  }

  function handleNext() {
    if (activeSlide === reviews.length) {
      setActiveSlide(1);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }

  return (
    <div className="industries section__padding" id="testimonials">
      <div className="industries-carousel__center">
        <ul className="industries-carousel__list">
          {reviews.map((review, index) => {
            const { text, image, title } = review;
            const count = index + 1;
            return (
              <li
                className={`industries-carousel__item
                    ${count === activeSlide ? " active" : ""}
                    ${count < activeSlide ? " left" : ""}
                    ${count > activeSlide ? " right" : ""}
                  `}
                key={index}
              >
                <div className="industries-carousel">
                  <div className="industries-carousel__content">
                    <h2>{title}</h2>
                    <p>{text}</p>
                  </div>
                  <div className="industries-carousel__image">
                    <img src={image} alt={title} />
                  </div>
                </div>
              </li>
            );
          })}
          <li className="industries-carousel__indicator">
            {reviews.map((review, index) => {
              const count = index + 1;
              return (
                <span
                  key={index}
                  onClick={() => setActiveSlide(count)}
                  className={`industries-carousel__dot${
                    count === activeSlide ? " active" : ""
                  }`}
                />
              );
            })}
          </li>
          <div className="industries-carousel__controls">
            <button
              className="industries-carousel__control industries-carousel__control--prev"
              onClick={handlePrev}
            >
              <BsChevronLeft />
            </button>
            <button
              className="industries-carousel__control industries-carousel__control--next"
              onClick={handleNext}
            >
              <BsChevronRight />
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default IndustriesCards;
