import React, { useState, useEffect } from "react";
import "./oilgas.css";
import Aos from "aos";
import "aos/dist/aos.css";
import oilgas1 from "../../assets/pic2.webp";
import oilgas2 from "../../assets/pic3.webp";
import subtitle from "../../assets/subtitle-line.webp";

const OilGas = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const images = [oilgas1, oilgas2];

  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((imageIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [imageIndex, images.length]);

  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <div className='oilgas section__padding' id='oilgas'>
      <div
        className='oilgas-content'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <h2 className='gradient__text'>ZeroT Oil/Gas</h2>
        <img src={subtitle} alt='' className='subtitle-line' />
        <p className='subtitle'>
          Optimizing Energy Production in the Oil/Gas industry
        </p>
        <ul className='list'>
          <li>Ageing equipment & Legacy systems</li>
          <li>Tracking Tanker Fleets in Real-Time</li>
          <li>Preventive Equipment Malfunction</li>
          <li>Productivity of Oil Wells and Forecasting</li>
          <li>Hazardous Waste recovery and treatment monitoring</li>
          <li>Production Management and Optimization</li>
          <li>Driven business intelligence and Data Analytics Platform</li>
        </ul>
      </div>
      <div
        className='oilgas-image'
        data-aos='fade-right'
        data-aos-easing='linear'
        data-aos-duration='1000'
      >
        <img className='image' src={images[imageIndex]} alt='oilgas' />
      </div>
    </div>
  );
};

export default OilGas;
