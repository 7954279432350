import React from "react";
import Article from "../../components/Article/Article";
import oilgas from "../../assets/gasoline.webp";
import iot from "../../assets/pic4.webp";
import opsplatform from "../../assets/pic6.webp";
import esg from "../../assets/environmental-protection.webp";
import manufacturing from "../../assets/worker.webp";
import utilities from "../../assets/utilities.webp";
import mining from "../../assets/mining.webp";
import healthcare from "../../assets/healthcare.webp";
import "./blogs.css";

const article = [
  {
    imgUrl: oilgas,
    date: "January 16, 2010",
    text: "What are the different categories of Oil found worldwide?",
  },
  {
    imgUrl: iot,
    date: "August 11, 2018",
    text: "What are the fundamental components of IoT?",
  },
  {
    imgUrl: opsplatform,
    date: "Sep 26, 2021",
    text: "What Is a Platform?",
  },
  {
    imgUrl: esg,
    date: "March 22, 2022",
    text: "Does driving the ESG agenda mean sacrificing company returns?",
  },
  {
    imgUrl: manufacturing,
    date: "April 28, 2022",
    text: "Manufacturing is the future. Let us exlpore how it is?",
  },
  {
    imgUrl: utilities,
    date: "July 8, 2022",
    text: "Utilities is the future. Let us exlpore how it is?",
  },
  {
    imgUrl: mining,
    date: "December 25, 2022",
    text: "How long can mining continue before the Earth is destroyed?",
  },
  {
    imgUrl: healthcare,
    date: "July 20, 2022",
    text: "Healthcare is the future. Let us exlpore how it is?",
  },
];

const Blogs = () => {
  return (
    <div className='blogs section__padding' id='blogs'>
      <div className='blogs__heading'>
        <h1 className='gradient__text'>Blogs</h1>
        <h2>A lot is happening, We are blogging about it.</h2>
      </div>
      <div className='blog-container'>
        {article.map((item, index) => (
          <Article
            key={index}
            imgUrl={item.imgUrl}
            date={item.date}
            text={item.text}
          />
        ))}
      </div>
    </div>
  );
};

export default Blogs;
