import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./company.css";
import "../../Pages/Services/CostManagement/services.css";
import "../../Pages/About/About.css";

const Company = () => {
  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 768,
    });
  }, []);

  return (
    <div
      className="company section__padding"
      id="company"
      data-aos="fade-down"
      data-aos-easing="ease-in"
      data-aos-duration="1500"
    >
      <div className="about">
        <div className="about__content">
          <h2>Who are we? | Our Vision </h2>
          <h4>
            <p>
              ZeroT has been developed with the concept of ZERO TOUCH Automation
              and Optimization of existing processes in any Industry where there
              is a scope to achieve Process Efficiency, Cost-Efficiency, and
              Energy Efficiency while applying AI and ESG principles.
            </p>
            <p>
              ZeroT is an AI-enabled Cognitive Ops platform. It has Visual Ops
              for hassle-free multi-cloud resource deployments, Compute, Edge,
              and IoT.
            </p>
            <p>
              Graphical representation of relationships between resources and AI
              insights for cost and performance management. The product can
              interact with diverse datasets to deliver insights to automate and
              provides information on Governance.
            </p>
            <p>
              Vastly reduce Infrastructure as Code (IaC) complexity and
              organizational skills gap using visual abstraction (VizOps) to
              existing IaC tools.
            </p>
            <p>
              Assimilate massive sensor data to build deep service awareness and
              deliver fine-grained automated infrastructure change management.
            </p>
            <p>
              Deliver dynamic, contextual and automated workflow Governance that
              meets the pace of automation, and drive intent-based business
              outcomes.
            </p>
          </h4>

          <h2 style={{ textAlign: "center" }}>Our Mission</h2>
          <h4>
            <p>
              ZeroT “Glass Plane” creates a portal for real-time
              technology/sensor observability, with in-depth service and
              business insights providing “Actionable Autonomous Intelligence”.
            </p>
            <p>
              To achieve DevOps Hand-off from Day 1 of the Process, to Increase
              Infrastructure Diversity and to create AI enable Recommendation
              system for Energy Efficiency
            </p>
          </h4>
        </div>
      </div>

      {/* <div className="company__content">
        <h2 className="gradient__text">Who we are</h2>
        <h2 className="gradient__text">Our Vision</h2>
        <p>
          ZeroT has been developed with the concept of ZERO TOUCH Automation and
          Optimization of existing processes in any Industry where there is a
          scope to achieve Process Efficiency, Cost-Efficiency, and Energy
          Efficiency while applying AI and ESG principles.
        </p>
        <p>
          ZeroT is an AI-enabled Cognitive Ops platform. It has Visual Ops for
          hassle-free multi-cloud resource deployments, Compute, Edge, and IoT.
        </p>
        <p>
          Graphical representation of relationships between resources and AI
          insights for cost and performance management. The product can interact
          with diverse datasets to deliver insights to automate and provides
          information on Governance.
        </p>
        <p>
          Vastly reduce Infrastructure as Code (IaC) complexity and
          organizational skills gap using visual abstraction (VizOps) to
          existing IaC tools.
        </p>
        <p>
          Assimilate massive sensor data to build deep service awareness and
          deliver fine-grained automated infrastructure change management.
        </p>
        <p>
          Deliver dynamic, contextual and automated workflow Governance that
          meets the pace of automation, and drive intent-based business
          outcomes.
        </p>
        <h2 className="gradient__text">Our Mission</h2>
        <p>
          ZeroT “Glass Plane” creates a portal for real-time technology/sensor
          observability, with in-depth service and business insights providing
          “Actionable Autonomous Intelligence”.
        </p>
        <p>
          To achieve DevOps Hand-off from Day 1 of the Process, to Increase
          Infrastructure Diversity and to create AI enable Recommendation system
          for Energy Efficiency
        </p>
      </div> */}
    </div>
  );
};

export default Company;
