import React from "react";
import "./animation.css";
import variant1 from "../../assets/variant1.webp";
import variant2 from "../../assets/variant2.webp";
import variant3 from "../../assets/variant3.webp";
import variant4 from "../../assets/variant4.webp";
import line1 from "../../assets/line1.webp";

const AnimationSolutionPage = () => {
  return (
    <>
      <div className='variant1'>
        <img src={variant1} alt='' />
      </div>
      <div className='variant2'>
        <img src={variant2} alt='' />
      </div>
      <div className='variantsolution3'>
        <img src={variant3} alt='' />
      </div>
      <div className='variantsolution4'>
        <img src={variant4} alt='' />
      </div>
      <div className='line1'>
        <img src={line1} alt='' />
      </div>
    </>
  );
};

export default AnimationSolutionPage;
