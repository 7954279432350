import React, { useState } from "react";
import * as XLSX from "xlsx";
import "./contact.css";

const Contact = () => {
  const [form, setForm] = useState({
    username: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { username, email, subject, message } = form;
    const data = [{ username, email, subject, message }];

    let wb;
    try {
      // Try to read the existing workbook
      wb = XLSX.readFile("./contact.xlsx");
    } catch {
      // If the file doesn't exist, create a new workbook
      wb = XLSX.utils.book_new();
    }

    // Check if the workbook has any sheets
    if (wb.SheetNames.length === 0) {
      // If not, create a new sheet and add the data to it
      console.log("No sheets");
      const ws = XLSX.utils.json_to_sheet(data, {
        header: ["username", "email", "subject", "message"],
      });
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    } else {
      console.log("Has sheets");
      // If the workbook has a sheet, get the first sheet
      const ws = wb.Sheets[wb.SheetNames[0]];
      // Append the data to the sheet
      XLSX.utils.sheet_add_json(ws, data, {
        origin: -1,
        header: ["username", "email", "subject", "message"],
      });
      // Update the workbook
      wb.Sheets[wb.SheetNames[0]] = ws;
    }

    // Write the workbook to the file
    XLSX.writeFile(wb, "./contact.xlsx");
  };

  return (
    <div className='contact-us section__padding' id='contact'>
      <h1 className='gradient__text'>Contact Us</h1>
      <p>
        Our attention is fully focused on You. Do not hesitate to contact us at
        any time.
      </p>
      <form onSubmit={handleSubmit}>
        <div className='contact-us__form'>
          <input
            type='text'
            name='username'
            placeholder='Name'
            value={form.username}
            onChange={handleChange}
          />
          <input
            type='email'
            name='email'
            placeholder='Email'
            value={form.email}
            onChange={handleChange}
          />
          <input
            type='text'
            name='subject'
            placeholder='Subject'
            value={form.subject}
            onChange={handleChange}
          />
          <textarea
            placeholder='Message'
            name='message'
            value={form.message}
            onChange={handleChange}
          />
          <button type='submit'>Send</button>
        </div>
      </form>
    </div>
  );
};

export default Contact;