import React from "react";
import "./event.css";

const Event = ({ imgUrl, date, text }) => {
  return (
    <div className='blog-container_event'>
      <div className='blog-container_event-image'>
        <img src={imgUrl} alt='blog_image' />
      </div>
      <div className='blog-container_event-content'>
        <div>
          <p>{date}</p>
          <h4>{text}</h4>
        </div>
      </div>
    </div>
  );
};

export default Event;
