import React from "react";
import "./footer.css";
import {
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import zerotenergy from "../../assets/zerotenergy.webp";
import { GoLocation, GoMail } from "react-icons/go";

const Footer = () => {
  return (
    <div className='footer footer__padding'>
      <div className='footer-links'>
        <div className='footer-links__logo'>
          <div className='footer-logo'>
            <img src={zerotenergy} alt='logo' />
          </div>
          <div className='footer-links_social_media'>
            <a
              href='https://www.facebook.com/profile.php?id=1000889505001742'
              className='circular-icon'
            >
              <FaFacebookF size={30} alt='' />
            </a>
            <a
              href='https://www.linkedin.com/company/zerotenergy/mycompany/'
              className='circular-icon'
            >
              <FaLinkedinIn size={30} alt='' />
            </a>
            <a
              href='https://twitter.com/zerot_energy'
              className='circular-icon'
            >
              <FaTwitter size={30} alt='' />
            </a>
            <a
              href='https://www.instagram.com/zerot_energy/'
              className='circular-icon'
            >
              <FaInstagram size={30} alt='' />
            </a>
          </div>
        </div>
        <div className='footer-links_container'>
          <div className='footer-links_div'>
            <h4>Quick Links</h4>
            <p>Features</p>
            <p>Pricing</p>
            <p>Careers</p>
            <p>Resources</p>
          </div>
          <div className='footer-links_div'>
            <h4>Company</h4>
            <div className='footer-links_icon'>
              <GoLocation size={20} style={{ fill: "#2080e2" }} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p>
                S1, Eden Springdale, M G Road, <br /> Pirattiyur West,
                Tiruchirapalli, <br /> Tamil Nadu, India, 62009
              </p>
            </div>
            <div className='footer-links_icon'>
              <BsTelephone size={20} style={{ fill: "#2080e2" }} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p>+91 7200728924</p>
            </div>
            <div className='footer-links_icon'>
              <GoMail size={20} style={{ fill: "#2080e2" }} />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <p>
                <a href='mailto:info@zerotenergy.com'>info@zerotenergy.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='footer-copyright'>
        <p>@2023 ZeroT Energy Pvt Ltd, All rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
