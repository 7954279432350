import React from "react";
import Event from "../../components/Event/Event";
import "./events.css";
import oilgas from "../../assets/pic2.webp";
import iot from "../../assets/pic3.webp";
import opsplatform from "../../assets/pic5.webp";
import esg from "../../assets/environmental-protection.webp";

const eventsDates = [
  {
    imgUrl: oilgas,
    date: "Jan 16, 2010",
    text: "Oil and Gas Industry",
  },
  {
    imgUrl: iot,
    date: "Aug 11, 2018",
    text: "Internet of Things",
  },
  {
    imgUrl: opsplatform,
    date: "Sep 26, 2021",
    text: "Operations Platform",
  },
  {
    imgUrl: esg,
    date: "Mar 22, 2022",
    text: "ESG",
  },
];
const Events = () => {
  return (
    <div className='events section__padding' id='events'>
      <div className='events__heading'>
        <h1 className='gradient__text'>Events</h1>
      </div>
      <div className='events-container'>
        {eventsDates.map((event, index) => (
          <Event
            imgUrl={event.imgUrl}
            date={event.date}
            text={event.text}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default Events;
