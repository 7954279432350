import React from "react";
import "./hero.css";
import hero from "../../assets/home.webp";

const Hero = () => {
  return (
    <div className="heroPage">
   

      <div className="hero section__padding" id="home">
        <div className="hero-content">
          <h1 className="h1 gradient__text ">
            Cognitive Operations and Actionable Intelligence
          </h1>
          <div>
            <div className="hero-text">
              <p
                className="gradient__text"
                style={{ textTransform: "capitalize" }}
              >
                Autonomous, Intent-based and application centric Infrastructure
                and Energy Management at Digital Scale and Pace.
              </p>
              <p
                className="gradient__text"
                style={{ textTransform: "uppercase" }}
              >
                ZEROT AIMS TO BE YOUR ONE STOP SOLUTION RANGING FROM AI
                RECOMMENDATIONS TO ESG INSIGHTS.
              </p>
            </div>
            <button className="hero-btns">Schedule Demo</button>
          </div>
        </div>
        <div className="hero-img">
          <img src={hero} alt="" />
        </div>

        </div>
        </div>
  );
};

export default Hero;
